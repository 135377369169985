<template>
    <el-dialog
            title="选择专题"
            :visible.sync="dialogVisible"
            width="800px"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :before-close="handleClose">
        <el-form :model="inputForm" inline ref="inputForm" label-width="100px"
                 size="small" @keyup.enter.native="getDataList(1,1)">
            <el-form-item label="专题名称" prop="name">
                <el-input v-model="inputForm.name"
                          placeholder="请输入专题名称(限50字)"
                          maxlength="50" clearable></el-input>
            </el-form-item>
            <span>
                  <el-button size="small" type="primary" @click="getDataList(1,1)"
                             icon="el-icon-search">查询</el-button>
            </span>
        </el-form>
        <el-table
            :data="dataList"
            size="small"
            v-loading="loading"
            ref="multipleTable"
            height="300px"
            class="table"
            :row-key="'id'"
            :header-cell-style="{background:'#F9DFDF'}"
            @selection-change="selectionChangeHandle"
        >
            <el-table-column :reserve-selection="true" type="selection" width="50"/>
            <el-table-column prop="thematicName" label="专题名称"
                             show-overflow-tooltip></el-table-column>
            <el-table-column prop="createUserName" label="创建人"
                             show-overflow-tooltip></el-table-column>
            <el-table-column prop="createDate" label="创建时间" width="150px">
                <template slot-scope="scope">
                    {{ scope.row.createDate | formatDate }}
                </template>
            </el-table-column>
        </el-table>
        <div class="text_center">
            <el-pagination
                @size-change="sizeChangeHandle"
                @current-change="currentChangeHandle"
                :current-page="current"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="size"
                :total="total"
                background
                layout="total, sizes, prev, pager, next"
            >
            </el-pagination>
        </div>
        <div class="text_center" style="margin-top: 10px">
            <el-button size="small" @click="handleClose()">取消</el-button>
            <el-button size="small" type="primary" @click="joinTheTopic()" v-no-more-click>加入专题</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: "specialList",
    data() {
        return {
            dialogVisible: false,
            inputForm: {
                name: '',
            },

            loading:false,
            dataList:[],
            dataListSelect: [],
            current: 1,
            size: 10,
            total: 0,

            collList:[],
        }
    },

    methods: {
        init(data){
            this.collList = data
            this.dialogVisible = true
            this.$nextTick(()=>{
                this.getDataList(1,1)
            })
        },

        getDataList(num, type) {
            if (num == 1) {
                this.current = 1
            }
            if (type) {
                this.$refs.multipleTable.clearSelection()
            }
            let data = {
                thematicName:this.inputForm.name,
                thematicType:'',
                current:this.current,
                size:this.size,
            }
            this.loading = true
            this.$axios(this.api.dyyg.commonList, data, 'get').then((res) => {
                if (res.status) {
                    this.dataList = res.data.records
                    this.$nextTick(() => {
                        this.$refs.multipleTable.doLayout()
                    })
                    this.total = parseInt(res.data.total)
                    if (this.dataList.length == 0 && this.pageNo > 1) {
                        this.pageNo--
                        this.getDataList()
                    }
                } else {
                    this.$message.error('查询失败');
                }
                this.loading = false
            })
        },

        //加入专题
        joinTheTopic(){
            if (this.dataListSelect.length == 0){
                this.$message.error('请选择要加入的专题')
                return
            }
            let resourceIds = this.collList.map(item=>{
                return item.id
            })
            let thematicIds = this.dataListSelect.map(item=>{
                return item.id
            })
            this.$axios(this.api.dyyg.thematiclibraryresourceBatchSave, {
                resourceIds,
                thematicIds,
            }, 'post').then((res) => {
                if (res.status) {
                    this.$message.success(res.msg)
                    this.$emit('specialYs')
                    this.handleClose()
                } else {
                    this.$message.error(res.msg);
                }
            })
        },

        //表格勾选数据
        selectionChangeHandle(val) {
            this.dataListSelect = val
        },

        // 展览每页数
        sizeChangeHandle(val) {
            this.size = val;
            this.getDataList(1, 1)
        },
        // 展览当前页
        currentChangeHandle(val) {
            this.current = val;
            this.getDataList();
        },

        handleClose() {
            this.$refs.inputForm.resetFields()
            this.dialogVisible = false
        },
    },
}
</script>

<style scoped>

</style>
